/* eslint-disable prettier/prettier */
import React from "react"

export default function Blogs() {
  return (
    <section id="blog">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center mb-4 reveal" data-reveal="fadeInUp">
              <h2 className="title-two">
                From <span>the blog</span>
              </h2>
            </div>
          </div>

          <div className="text-center mb-2 reveal" data-reveal="fadeInUp">
            <p>
              Lorem ipsum dolor sit amet, consectetured adipiscing elit, sed do
              eiusmod tempor inci didunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exer citation ullamco laboris.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="blog-item reveal" data-reveal="fadeInUp">
              <div className="item">
                <div className="item-image mb-3">
                  <img
                    className="img-fluid"
                    src="images/blog/blog1.jpg"
                    alt=""
                  />
                </div>
                <div className="item-content">
                  <h3>Work Strategy</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetured adipiscing elit,
                    sed do eiusmod tempor inci didunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <p className="postedby">
                    Posted by
                    <a href="#" className="theme-color">
                      julio
                    </a>
                    on <span className="theme-color">21 May 2023</span>
                  </p>
                  <div className="ruler"></div>
                  <a
                    className="btn btn-info"
                    href="blog-description.html"
                    target="_blank"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-item reveal" data-reveal="fadeInUp">
              <div className="item">
                <div className="item-image mb-3">
                  <img
                    className="img-fluid"
                    src="images/blog/blog2.jpg"
                    alt=""
                  />
                </div>
                <div className="item-content">
                  <h3>Work Strategy</h3>

                  <p>
                    Lorem ipsum dolor sit amet, consectetured adipiscing elit,
                    sed do eiusmod tempor inci didunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <p className="postedby">
                    Posted by
                    <a href="#" className="theme-color">
                      julio
                    </a>
                    on <span className="theme-color">21 May 2023</span>
                  </p>
                  <div className="ruler"></div>
                  <a
                    className="btn btn-info"
                    href="blog-description.html"
                    target="_blank"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-item reveal" data-reveal="fadeInUp">
              <div className="item">
                <div className="item-image mb-3">
                  <img
                    className="img-fluid"
                    src="images/blog/blog3.jpg"
                    alt=""
                  />
                </div>

                <div className="item-content">
                  <h3>Work Strategy</h3>

                  <p>
                    Lorem ipsum dolor sit amet, consectetured adipiscing elit,
                    sed do eiusmod tempor inci didunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <p className="postedby">
                    Posted by
                    <a href="#" className="theme-color">
                      julio
                    </a>
                    on <span className="theme-color">21 May 2023</span>
                  </p>
                  <div className="ruler"></div>
                  <a
                    className="btn btn-info"
                    href="blog-description.html"
                    target="_blank"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
