/* eslint-disable prettier/prettier */
import React from "react";
import btLogo from '../assets/images/bt-logo-anime.svg';

export default function Header() {
  return (<><nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainMenu">
  <div className="container">
    {/* <a className="navbar-brand" href="#"><img src="images/logo.png" /></a> */}
    <div className="navbar-brand">
      {/* <h1>BT Technologies</h1> */}
     <img src={btLogo} className="img-fluid img-logo" alt="BT Logo" />
    </div>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-controls="main_nav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="main_nav">
      <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0 navbar-nav-scroll">
        <li className="nav-item">
          <a className="nav-link formobile active" href="/">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link formobile" href="#our_services">Our Services</a>
        </li>
        {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="ddservices" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</a>
          <ul className="dropdown-menu fade-up first-level" aria-labelledby="ddservices">
             <li>
              <a className="dropdown-item formobile" href="inner-page.html">Inner Page</a>
            </li> 
            <li>
              <a className="dropdown-item formobile" href="#">Menu Item 2</a>
            </li>
            <li>
              <a className="dropdown-item formobile" href="#">Menu Item 3</a>
            </li>
            <li>
              <a className="dropdown-item" href="#">Menu Item 4 &raquo;</a>
              <ul className="submenu submenu-right dropdown-menu">
                <li>
                  <a className="dropdown-item formobile" href="#">Submenu Item 1</a>
                </li>
                <li>
                  <a className="dropdown-item formobile" href="#">Submenu Item 2</a>
                </li>
                <li>
                  <a className="dropdown-item formobile" href="#">Submenu Item 3</a>
                </li>
                <li>
                  <a className="dropdown-item formobile" href="#">Submenu Item 4</a>
                </li>
              </ul>
            </li>
            <li>
              <a className="dropdown-item formobile" href="#">Menu Item 5</a>
            </li>
          </ul>
        </li> */}
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="ddblog" data-bs-toggle="dropdown" aria-expanded="false">Blog</a>
          <ul className="dropdown-menu fade-up first-level" aria-labelledby="ddblog">
            <li><a className="dropdown-item formobile" href="#">Blog Listing</a></li>
            <li><a className="dropdown-item formobile" href="#">Blog Description</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link formobile" href="#project">Projects</a>
        </li>
      </ul>
      <a className="btn btn-default internal formobile" href="#contact_us" role="button">Get Started</a>
    </div>
  </div>
</nav></>);
}
