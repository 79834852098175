/* eslint-disable prettier/prettier */
import logo from "./logo.svg"
import { Counter } from "./features/counter/Counter"
import "./App.css"
import "bootstrap/scss/bootstrap.scss"
import "./style.scss"
import "./animation.scss"
import { Routes, Route, useNavigate } from "react-router"
import { BrowserRouter } from "react-router-dom"
import Layout from "./templates/Layout"
import Home from "./pages/home/Home"
import Project from "./pages/project/Project"

function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/project" element={<Project />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  )
}

export default App
